/* global D,Flickity,TweenLite,TimelineLite,Expo,Quart */

import React, { useEffect,useCallback } from 'react'
import { each } from 'lodash'
//import { TweenMax,TweenLite,TimelineLite,Expo } from 'gsap/all'

import Form from './components/form'
import Modal from './components/modal'

import { ReactComponent as Logo } from './assets/images/logo-hosting.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { library } from '@fortawesome/fontawesome-svg-core'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faHdd,faCog,faCloudUploadAlt,faTachometerAlt,faShieldAlt,faClock,faServer,faEnvelope,faPhone,faComments,faUserCog } from '@fortawesome/free-solid-svg-icons'

import './styles/style.scss'

const App = () =>
{
	const initFontAwesome = useCallback(() =>
	{
		//library.add(faHdd,faLockOpen,faGlobeAmericas,faCog,faCloudUploadAlt,faTachometerAlt,faShieldAlt,faFingerprint,faClock,faUser,faHeadphonesAlt,faTwitter,faEnvelope,faPhone,faComments,faUserCog)

	},[])

	const start = useCallback(() =>
	{
		const loader = document.querySelector('#loader')
		const root = document.querySelector('#root')

		// eslint-disable-next-line
		var flkty = new Flickity( '.slider', 
		{
			//freeScroll: true,
			wrapAround: true,
			prevNextButtons: false,
			pageDots: true,
			//freeScrollFriction: 0.00,
			lazyLoad: 2,
			imagesLoaded: true,
			autoPlay: true
		});

		const TL = new TimelineLite()
		TL
			.to(loader, 1, { autoAlpha:0, ease:Expo.easeOut },1)
			.to(root, 1, { autoAlpha:1, ease:Expo.easeOut },'-=0.5')
			.call(() => 
			{
				document.body.classList.add('loaded')
				TweenLite.set('#loader', { display:'none' })
			},[],this)
		;

	},[])
	
	// SIDE EFFECTS
	//
	
		useEffect(() =>
		{
			initFontAwesome()
			start()

		},[])

	
	return [
		<div key='header' id='header'>
			<div className='links'>
				<button className='button link'><FontAwesomeIcon icon={faUserCog} className='icon' />My Account</button>
				{/* <button className='button link'><FontAwesomeIcon icon={faHeadphonesAlt} className='icon' />Live Chat</button> */}
				{/* <button className='button link'><FontAwesomeIcon icon={faPhone} className='icon' />1.888.888.8888</button> */}
				{/* <button className='button link'><FontAwesomeIcon icon={faComments} className='icon' />Support Ticket</button> */}
				<button className='button link'><FontAwesomeIcon icon={faEnvelope} className='icon' />Support</button>
			</div>
		</div>,
		<div key='app' className='app'>
			
			<header className='app__header'>
				<div className='brand-lockup'>
					<FontAwesomeIcon icon={faHdd} className='icon' />
					<Logo className='logo' />
					<small>DROPLAB <span>HOSTING</span></small>
				</div>

				<span className='nav-highlight'>
					<span className='name'>HOME</span>
				</span>

				<nav className='main'>
					<button className='button link' onClick={e => this.gotoSection(e,'home')}>HOME</button>
					{/* <button className='button link' onClick={e => this.gotoSection(e,'pages')}>PAGES</button> */}
					<button className='button link' onClick={e => this.gotoSection(e,'hosting-plans')}>PLANS</button>
					<button className='button link' onClick={e => this.gotoSection(e,'hosting-features')}>FEATURES</button>
					<button className='button link' onClick={e => this.gotoSection(e,'services')}>CUSTOM SOLUTIONS</button>
					<button className='button link' onClick={e => this.gotoSection(e,'whmcs')}>DESIGN/DEVELOPMENT</button>
				</nav>
			</header>

			<article className='app__content'>
				{/*
				<Form 
					analytics={this.analytics}
					showModal={this._showModal}
				/>
				*/}
				<div className='slider'>
					<div className='panel'>
						<div className='lockup'>
							<strong>Modern Cloud Hosting</strong><br />
							<em>Fast, scalable and reliable hosting for today's web</em><br />
							<button className='button cta'>learn more</button>
						</div>
					</div>
					<div className='panel'>
						<div className='lockup'>
							<strong>Turbo Boosted Performance</strong><br />
							<em>Powered by 100% SSD and latest generation Intel CPUs running on the powerful Vultr global infrastructure.</em><br />
							<button className='button cta'>order now</button>
						</div>
					</div>
					<div className='panel'>
						<div className='lockup'>
							<strong>Fully Managed</strong><br />
							<em>We handle the technical stuff so you can run your business.</em><br />
							<button className='button cta'>order now</button>
						</div>
					</div>
					<div className='panel'>
						<div className='lockup'>
							<strong>Site Design, Code & Support</strong><br />
							<em>Design, architect and support modern sites, stacks and publishing platforms</em><br />
							<button className='button cta'>contact us</button>
						</div>
					</div>
					
							
							
							
				</div>

				<em className='plans-header'>hosting plans</em>

				<div className='plans'>
					<div className='plans__summary-card'>
						<h5 className='title'>STARTUP PLAN</h5>
						<strong className='price'><span>$</span>25</strong>
						<ul className='features'>
							<li>25GB Disk Space</li>
							<li>1GB RAM</li>
							<li>1TB Bandwidth<sup>**</sup></li>
							<li>Automatic Backups</li>
							<li>Managed Hosting</li>
						</ul>
						<button className='button link'>ORDER NOW!</button>
					</div>

					<div className='plans__summary-card'>
						<h5 className='title'>GROWTH PLAN</h5>
						<strong className='price'><span>$</span>45</strong>
						<ul className='features'>
							<li>55GB Disk Space</li>
							<li>2GB RAM</li>
							<li>2TB Transfer<sup>**</sup></li>
							<li>Automatic Backups</li>
							<li>Managed Hosting</li>
						</ul>
						<button className='button link'>ORDER NOW!</button>
					</div>

					<div className='plans__summary-card'>
						<h5 className='title'>ADVANCED PLAN</h5>
						<strong className='price'><span>$</span>100</strong>
						<ul className='features'>
							<li>80GB Disk Space</li>
							<li>4GB RAM</li>
							<li>3TB Transfer<sup>**</sup></li>
							<li>Automatic Backups</li>
							<li>Managed Hosting</li>
						</ul>
						<button className='button link'>ORDER NOW!</button>
					</div>

					<div className='plans__summary-card'>
						<h5 className='title'>BUSINESS PLAN</h5>
						<strong className='price'><span>$</span>150</strong>
						<ul className='features'>
							<li>160GB Disk Space</li>
							<li>8GB RAM</li>
							<li>4TB Transfer<sup>**</sup></li>
							<li>Automatic Backups</li>
							<li>Managed Hosting</li>
						</ul>
						<button className='button link'>ORDER NOW!</button>
					</div>

					{/* <div className='plans__summary-card'>
						<h5 className='title'>ENTERPRISE PLAN</h5>
						<strong className='price'><span>$</span>Get A Quote</strong>
						<ul className='features'>
							<li>Configurable Disk Space</li>
							<li>Configurable RAM</li>
							<li>Configurable Transfer<sup>**</sup></li>
							<li>Automatic Backups</li>
							<li>Managed Hosting</li>
						</ul>
						<button className='button link'>CONTACT US!</button>
					</div> */}
				</div>

				<div className='need-help'>
					<h4 className='title'>NOT SURE WHICH HOSTING PLAN TO CHOOSE?</h4>
					{/* <p className='blurb'>No problem. Send us a ticket with your hosting needs and we’ll get back to you with a quote. Free of charge.</p> */}
					<p className='blurb'>No problem, we're happy to help! Just answer a few questions by clicking the button below and we’ll get back to you with a quote. Free of charge.</p>
					<button className='button cta'>free quote</button>
				</div>

				<em className='hosting-features-header'>hosting features</em>

				<ul className='hosting-features'>
					
					<li className='item'>
						<h5 className='title'>Fully Managed Hosting</h5>
						<div className='desc'>
							<p className='blurb'>Tired of trying to find that login to cPanel or some admin dashboard that you don't understand how to use? <strong>We got you covered</strong>.<br /><br />We will setup, configure and manage your entire web stack so you can focus on what's important... <em className='underline'>running your business</em>.</p>
							<div className='icon'>
								<FontAwesomeIcon icon={faShieldAlt} />
							</div>
						</div>
					</li>
					<li className='item'>
						<h5 className='title'>High performance architecture</h5>
						<div className='desc'>
							<p className='blurb'>Powered by the Vultr cloud, compute instances are designed for the most demanding workloads and feature 100% local SSD and high performance Intel CPUs.</p>
							<div className='icon'>
								<FontAwesomeIcon icon={faTachometerAlt} />
							</div>
						</div>
					</li>
					<li className='item'>
						<h5 className='title'>Instant deployments worldwide</h5>
						<div className='desc'>
							<p className='blurb'>We can spin up a new instance with your preferred operating system or pre-installed application in any of 16 locations across the Vultr global network.</p>
							<div className='icon'>
								<FontAwesomeIcon icon={faCog} />
							</div>
						</div>
					</li>
					<li className='item'>
						<h5 className='title'>Powerful Compute Instances</h5>
						<div className='desc'>
							<p className='blurb'>High performance compute instances are perfect for web applications, sites and online destinations.</p>
							<div className='icon'>
								<FontAwesomeIcon icon={faServer} />
							</div>
						</div>
					</li>
					<li className='item'>
						<h5 className='title'>Powerful add-ons</h5>
						<div className='desc'>
							<p className='blurb'>We can enhance the capabilities of your servers with automated snapshots, firewalls, flexible networking, block storage and DDoS protection<sup>***</sup></p>
							<div className='icon'>
								<FontAwesomeIcon icon={faCloudUploadAlt} />
							</div>
						</div>
					</li>
					<li className='item'>
						<h5 className='title'>100% SLA guaranteed</h5>
						<div className='desc'>
							<p className='blurb'>The Vultr cloud brings enterprise grade stability and performance, so you can rest assured your site(s) are always online... and performant.</p>
							<div className='icon'>
								<FontAwesomeIcon icon={faClock} />
							</div>
						</div>
					</li>
				</ul>

				<div className='footnotes'>
					<aside className='footnotes__note'>** Bandwidth overages are billed at a per GB rate. See <a href='/faq'>FAQs</a> for more details</aside>
					<aside className='footnotes__note'>*** Add-ons not included with hosting packages and billed separately.</aside>
				</div>

			</article>
			
		</div>,
		<footer key='footer' id='footer'>
			<div className='sprite logo' onClick={() => this.linkOut('dwp')}></div>
			<div className='legal'>
				<div className='copyright'>&copy; 2020 Droplab, Inc. All Rights Reserved.</div>
				<div className='links'>
					<button onClick={() => this.showModal('privacy',{})}>Privacy</button> 
					<span>&</span> 
					<button onClick={() => this.showModal('terms',{})}>Terms</button>
				</div>
			</div>
				
		</footer>
	]

	{/*
		<Modal 
			key='modal' 
			type={this.state.modal}
			closeModal={this._hideModal} 
		/>
		*/}
}

export default App